body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.pointer {
    cursor: pointer;
}

/* components/Common/ValidationInput */
.glyphicon.glyphicon-remove.form-control-feedback {
    right: 15px;
}

/* containers/Home */
.home-container-link-container {
    margin-bottom: 20px;
}

/* components/PaymentForm */
.payment-form-component-save-account-link {
    font-size: 11px;
}

/* components/Info */
.x-button {
    cursor: pointer;
    margin-left: 45px;
}

.third-width {
    width: 33.33333%;
}

.ellipsis {
    overflow-y: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.hover-item:hover {
    box-shadow: 5px 5px 5px #2c3142;
}

.title-help {
    font-size: 13px;
    font-style: italic;
}

.glyphicon {
    font-family: 'Glyphicons Halflings' !important;
}

.dropdown-menu>.active>a, 
.dropdown-menu>.active>a:focus, 
.dropdown-menu>.active>a:hover {
    background-color: #2c3142 !important;
    color: #ffffff !important;
}

.open>.dropdown-toggle.btn-primary {
    background-color: #2c3142 !important;
    border-color: #12151C !important;
}

.breadcrumb a,
.react-bootstrap-table a, 
.react-bootstrap-table .btn-link,
.form-horizontal .btn-link {
    color: #000a2d !important;
}

.alert-success {
    border-color: transparent; 
}


.list-group-item.active, 
.list-group-item.active:focus, 
.list-group-item.active:hover {
    background-color: #2c3142;
    border-color: #12151C;
}


.list-group-item.active>.badge, 
.nav-pills>.active>a>.badge {
    color: #2c3142;
    background-color: #fff;
}

.issued-contracts-btns .btn {
    margin-right: 5px;
}

.pagination>.active>a, 
.pagination>.active>a:focus, 
.pagination>.active>a:hover, 
.pagination>.active>span, 
.pagination>.active>span:focus, 
.pagination>.active>span:hover {
    background-color: #2c3142;
    border-color: #2c3142;
}

.pagination>li>a, 
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #2c3142;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

select option:checked {
    background: #2c3142 -webkit-linear-gradient(bottom, #2c3142 0%, #2c3142 100%);
    color: #ffffff;
}